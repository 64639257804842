<template>
  <header class="shadow-box-light dark:shadow-box-dark h-20 px-6">
    <div class="header--nav">
      <div class="header--nav--left">
        <h2
          class="dark:text-white font-bold text-xl lg:text-2xl"
          v-html="$t('sidebar-section.title')"
        ></h2>
      </div>
      <div class="header--nav--right">
        <ul class="header--nav--language">
          <li>
            <button
              title=""
              class="header--nav--link"
              @click="toggleLang('en')"
              :class="{ active: $i18n.locale === 'en' }"
            >
              Eng
            </button>
          </li>
          <li>|</li>
          <li>
            <button
              title=""
              class="header--nav--link"
              @click="toggleLang('es')"
              :class="{ active: $i18n.locale === 'es' }"
            >
              Esp
            </button>
          </li>
        </ul>
        <router-link
          v-if="!isAuthenticated"
          :to="{ name: 'SignInEmpresas' }"
          class="header--nav--link--active"
        >
          <span @click="handleCloseMenu" class="menu-span">
            {{ $t('header.sign-in') }}
          </span>
        </router-link>
        <!-- If User is Login -->
        <div
          v-if="isAuthenticated"
          class="header--user header--nav--dropdown cursor-pointer"
        >
          <router-link :to="{ name: 'ClientsProfile' }">
            <div class="header--user--info">
              {{ $t('sidebar-section.user-test') }}
              <img
                :src="require(`@/assets/images/avatars/avatar-1.svg`)"
                class="header--user--image ml-2"
              />
              <!-- <img
                src="@/assets/images/icn-chevron-down.svg"
                class="ml-2"
                alt="Dropdown"
              /> -->
            </div>
          </router-link>
        </div>
        <!-- <button
            v-if="isAuthenticated"
            type="button"
            class="header--nav--link--active"
            @click="signOut"
          >
            Sign Out
          </button> -->
        <!-- <SwitchThemeColor /> -->
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ROUTES from '@/constants/routes'
// import SwitchThemeColor from '@/components/SwitchThemeColor'

export default {
  name: 'Header',
  components: {
    // SwitchThemeColor
  },
  computed: {
    ...mapGetters(['user', 'isAuthenticated', 'lang'])
  },
  onMounted() {
    this.fetchUser()
  },
  methods: {
    ...mapActions(['signOut']),
    handleCloseMenu() {
      this.isShowingMenu = false
      this.isShowingMenuProfile = false
      this.isShowingMenuLearn = false
    },
    toggleLang(lang) {
      this.$root.$i18n.locale = lang
      const to = this.$router.resolve({ params: { locale: lang } })
      this.$router.push(to.fullPath)
    }
  },
  data: function () {
    return {
      ROUTES,
      isShowingMenu: false,
      isShowingMenuProfile: false,
      isShowingMenuLearn: false
    }
  }
}
</script>
