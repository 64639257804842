<template>
  <Loading v-if="loading" class="absolute w-full h-full z-20 left-0 top-0 right-0 bottom-0 loading--dark"></Loading>
  <main class="main--wrapper py-24 lg:pt-52 lg:pb-40">
    <div class="content--wrapper">
      <div class="w-full border lg:w-5/12 xl:w-5/12 border-1.5 mx-auto border-primary rounded-xl">
        <!-- Card Page Sign Up -->
        <CardPage class="relative">
          <template #default>
            <!-- <CodyAnimations :animado="completeFields" class="cody--card" /> -->

            <Form @submit="signUp" id="multiStepForm">
              <div v-if="step == 1">
                <figure class="flex justify-center absolute -top-24 left-1/2 transform -translate-x-1/2">
                  <img src="@/assets/images/utopicode-icon-laptop.svg" alt="img" />
                </figure>
                <!-- Title -->
                <div class="card--page--header mx-auto pt-40 w-full lg:w-8/12 xl:w-8/12">
                  <Title>
                    <template #title>
                      <h3>{{ $t('registrarse-page.title') }}</h3>
                    </template>
                  </Title>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div class="card--page--body">
                  <div class="form--group">
                    <Field name="email" type="email" class="form--element w-full" aria-placeholder="Email"
                      :placeholder="$t('loguearse-page.lbl-email')" v-model="email" :required="true" />
                    <ErrorMessage name="email" class="form--error" />
                  </div>

                  <div>
                    <!-- <button
                      class="btn btn-lg btn-secondary btn-rounded-lg mx-auto"
                      role="button"
                      @click.prevent="prevStep"
                    >
                      {{ $t('registrarse-page.btn-regresar') }}
                    </button> -->
                    <button class="btn btn-lg btn-primary btn-rounded-lg mx-auto" role="button"
                      @click.prevent="nextStep">
                      {{ $t('registrarse-page.btn-register') }}
                    </button>
                  </div>
                  <!-- Agregar texto y botón para redirigir a Sign In -->
                </div>
                <!-- End Body -->
                <!-- End Options -->
                <div class="card--page--option">
                  <div class="card--page--option--divider">
                    <h4>{{ $t('registrarse-page.texto-ingresa-con') }}</h4>
                  </div>
                  <div class="card--page--body">
                    <ul class="flex space-x-4 w-full">

                      <!-- <li class="w-full" v-if="false">
                        <button
                          type="button"
                          class="btn btn-lg btn-full btn-rounded-lg btn-linkedin"
                        >
                          <img
                            src="@/assets/images/social-media/icn-linkedin.svg"
                            alt="LinkedIn"
                          />
                        </button>
                      </li> -->
                      <li class="w-full">
                        <button type="button" class="btn btn-lg btn-full btn-rounded-lg btn-gmail"
                          @click="socialLogin({ type: 'gmail', router: $router })">
                          <img src="@/assets/images/social-media/icn-gmail.svg" alt="Gmail" />
                        </button>
                      </li>
                      <!-- <li class="w-full">
                        <button type="button" class="btn btn-lg btn-full btn-rounded-lg btn-facebook"
                          @click="socialLogin({ type: 'facebook', router: $router })">
                          <img src="@/assets/images/social-media/icn-facebook.svg" alt="Facebook" />
                        </button>
                      </li> -->
                      <li class="w-full">
                        <button type="button" class="btn btn-lg btn-full btn-rounded-lg btn-github"
                          @click="socialLogin({ type: 'github', router: $router })">
                          <img src="@/assets/images/social-media/icn-github.svg" alt="Github" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="card--page--footer">
                  <div class="flex items-baseline justify-end space-x-6">
                    <p class="dark:text-primary-text text-secondary-text mb-0">
                      ¿Ya tienes una cuenta?
                    </p>
                    <router-link :to="{
                      path: ROUTES.signin,
                      query: isQuery ? { redirectTo: isQuery } : null
}" class="btn btn-lg btn-primary btn-rounded-lg">Sign In</router-link>
                  </div>
                </div>
                <!-- End Options -->
              </div>

              <div v-if="step == 2">
                <figure class="flex justify-center absolute top-[-2rem] right-[-35%]">
                  <img src="@/assets/images/utopicode-register.png" alt="img" />
                </figure>
                <!-- Title -->
                <div class="card--page--header mx-auto w-full lg:w-8/12 xl:w-8/12">
                  <Title>
                    <template #title>
                      <div class="flex justify-center">
                        <svg width="297" height="69" viewBox="0 0 297 69" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect x="34.9277" width="48.695" height="48.695" rx="5.37846" transform="rotate(45 34.9277 0)"
                            fill="#B9A3FF" />
                          <path
                            d="M37.4738 40.3608H41.9906V43.3906H29.3661V40.3608H34.2476V26.8386L33.939 26.7544L30.6566 29.644L28.6087 27.0911L34.472 22.6024H37.4738V40.3608Z"
                            fill="#1E1D1D" />
                          <rect x="66.9453" y="33.5273" width="68.1265" height="1.8121" fill="#B9A3FF" />
                          <rect x="157.863" y="10.3582" width="34.0457" height="34.0457" rx="4.57691"
                            transform="rotate(45 157.863 10.3582)" stroke="#B9A3FF" stroke-width="1.6031" />
                          <path
                            d="M155.832 39.5904V39.8092H161.538V41.1895H153.711V39.8428L158.845 34.7931C159.72 33.9346 160.057 33.1772 160.057 32.285C160.057 30.7364 159.047 29.8948 157.667 29.8948C156.219 29.8948 155.209 30.8206 155.108 32.2009H153.644C153.829 29.979 155.495 28.5482 157.785 28.5482C159.939 28.5482 161.538 29.7938 161.538 32.0999C161.538 33.3791 161.033 34.4733 159.67 35.803L155.832 39.5904Z"
                            fill="white" />
                          <rect x="180.656" y="33.5273" width="68.1265" height="1.8121" fill="#B9A3FF" />
                          <rect x="271.574" y="10.3582" width="34.0457" height="34.0457" rx="4.57691"
                            transform="rotate(45 271.574 10.3582)" stroke="#B9A3FF" stroke-width="1.6031" />
                          <path
                            d="M273.717 34.8099C274.98 35.2812 275.754 36.3585 275.754 37.8229C275.754 39.9607 274.138 41.4083 271.731 41.4083C269.324 41.4083 267.557 39.927 267.338 37.5536H268.752C268.819 39.0854 269.964 40.0953 271.613 40.0953C273.212 40.0953 274.273 39.1359 274.273 37.7219C274.273 36.3248 273.212 35.3991 271.63 35.3991H270.688V34.1703H271.411C272.977 34.1703 274.004 33.3455 274.004 32.0494C274.004 30.7533 272.943 29.8611 271.479 29.8611C269.93 29.8611 268.887 30.8206 268.735 32.184H267.321C267.607 29.9958 269.274 28.5482 271.597 28.5482C273.886 28.5482 275.485 29.9453 275.485 31.9484C275.485 33.2108 274.828 34.1534 273.717 34.6079V34.8099Z"
                            fill="white" />
                        </svg>
                      </div>
                      <div class="flex mt-3 justify-center items-center flex-grow-0 flex-shrink-0 relative"
                        style="min-height: -webkit-fill-available">
                        <div class="flex-grow-0 flex-shrink-0 relative">
                          <p class="left-0 inline mb-0 top-0 text-3xl text-center dark:text-white">
                            Iniciem <span>&nbsp;</span> s
                          </p>
                          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="absolute top-[12px] right-[15px]" preserveAspectRatio="xMidYMid meet">
                            <path
                              d="M13.6727 11.2785L7.96204 8.50613L13.6748 5.73319C13.8369 5.64298 13.9693 5.45498 14.0292 5.22323C14.0897 4.98823 14.0654 4.75108 13.9639 4.58847C13.8526 4.38859 13.6165 4.23139 13.3426 4.1698C13.1298 4.12172 12.9337 4.14171 12.8191 4.22382L7.49259 7.83032L7.99175 1.45197C8.02471 1.16349 7.92963 0.951184 7.70976 0.813429C7.39481 0.616789 6.89187 0.625973 6.56396 0.835037C6.40838 0.934437 6.23172 1.12027 6.27224 1.44332L6.77248 7.82978L1.44972 4.22598C1.06724 4.01422 0.512439 4.21356 0.307697 4.52634C0.0905293 5.01902 0.280685 5.57815 0.592391 5.73373L6.3025 8.50613L0.595093 11.2769C0.426005 11.3725 0.295273 11.5589 0.235849 11.789C0.175344 12.024 0.199654 12.2612 0.301214 12.4232C0.412499 12.6231 0.648575 12.7803 0.922465 12.8419C1.13531 12.89 1.33141 12.87 1.44594 12.7879L6.77248 9.1814L6.27332 15.5598C6.23226 15.8914 6.40892 16.0773 6.5645 16.1767C6.89187 16.3857 7.39536 16.3949 7.7103 16.1983C7.93017 16.0605 8.02525 15.8482 7.99284 15.5668L7.49259 9.18194L12.8154 12.7857C13.1984 12.9975 13.7532 12.7982 13.9574 12.4854C14.1745 11.9932 13.9844 11.4341 13.6727 11.2785Z"
                              fill="#FF9980"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative"
                        style="min-height: -webkit-fill-available">
                        <div class="flex-grow-0 flex-shrink-0 flex items-center">
                          <p class="left-0 mb-0 top-0 text-3xl text-center dark:text-white">
                            Déjanos conocerte un poco
                          </p>
                        </div>
                      </div>
                    </template>
                  </Title>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div class="card--page--body">
                  <div class="form--group">
                    <Field name="fullname" type="text" class="form--element w-full" :rules="validateDefault"
                      :placeholder="$t('registrarse-page.lbl-nombre-completo')" v-model="firstname" :required="true" />
                    <ErrorMessage name="fullname" class="form--error" />
                  </div>
                  <div class="form--group">
                    <Field name="lastname" type="text" class="form--element w-full" :rules="validateDefault"
                      v-model="lastname" :placeholder="$t('registrarse-page.lbl-lastname')" :required="true" />
                    <ErrorMessage name="fullname" class="form--error" />
                  </div>
                  <div class="form--group">
                    <div class="form--phone">
                      <Field name="phoneCode" type="text" :placeholder="$t('general.codigo-internacional')"
                        list="phoneCodeNumberList" class="form--element form--select w-24" :rules="validateDefaultPhone"
                        v-model.lazy="phoneCodeNumber" :required="true" />
                      <Field name="phone" type="text" :placeholder="$t('general.phone')" class="form--element w-full"
                        :rules="validatePhone" v-model="phoneNumber" :required="true" />
                      <datalist id="phoneCodeNumberList">
                        <option value="" disabled></option>
                        <option :value="code.dialCode" v-for="(code, index) in phoneCodes" :key="index">
                          {{ code.emoji }} {{ code.name }}
                        </option>
                      </datalist>
                    </div>
                    <ErrorMessage name="phone" class="form--error" />
                    <ErrorMessage name="phoneCode" class="form--error" />
                    <div class="form--error" v-if="error">
                      {{ error }}
                    </div>
                  </div>

                  <div class="flex justify-between">
                    <button class="btn btn-lg btn-secondary btn-rounded-lg text-black" role="button"
                      @click.prevent="prevStep">
                      <svg style="transform: rotate(180deg)" width="41" height="19" viewBox="0 0 41 19" fill="none"
                        xmlns="http://www.w3.org/2000/svg" class="mr-2">
                        <g clip-path="url(#clip0_1903_17424)">
                          <path
                            d="M40.8567 8.10463C39.0941 7.58831 37.4561 6.78713 35.9962 5.70107C34.1267 4.31235 32.56 2.44291 31.5095 0.324219L30.3878 0.876148C31.5451 3.17288 33.2187 5.16695 35.2484 6.6803C35.9427 7.19662 36.6727 7.65953 37.4561 8.06903H0.298828V9.31532H37.7588C36.9576 9.77822 36.2098 10.2945 35.5154 10.8999C32.8092 13.2322 31.5629 16.0275 30.9754 17.9503L32.1683 18.3064C32.7024 16.526 33.8419 13.9622 36.3166 11.8435C37.6519 10.704 39.1831 9.83164 40.8745 9.27971L40.8567 8.10463ZM38.7914 8.78119V8.69217C38.827 8.70998 38.8448 8.70998 38.8804 8.72778C38.8448 8.74558 38.827 8.76339 38.7914 8.78119Z"
                            fill="#1E1D1D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1903_17424">
                            <rect width="40.5757" height="18" fill="white" transform="translate(0.298828 0.324219)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ $t('registrarse-page.btn-regresar') }}
                    </button>
                    <button class="btn btn-lg btn-primary btn-rounded-lg" role="button" @click.prevent="nextStep">
                      {{ $t('registrarse-page.btn-register-next') }}
                      <svg width="41" height="19" viewBox="0 0 41 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="ml-2">
                        <g clip-path="url(#clip0_1903_17424)">
                          <path
                            d="M40.8567 8.10463C39.0941 7.58831 37.4561 6.78713 35.9962 5.70107C34.1267 4.31235 32.56 2.44291 31.5095 0.324219L30.3878 0.876148C31.5451 3.17288 33.2187 5.16695 35.2484 6.6803C35.9427 7.19662 36.6727 7.65953 37.4561 8.06903H0.298828V9.31532H37.7588C36.9576 9.77822 36.2098 10.2945 35.5154 10.8999C32.8092 13.2322 31.5629 16.0275 30.9754 17.9503L32.1683 18.3064C32.7024 16.526 33.8419 13.9622 36.3166 11.8435C37.6519 10.704 39.1831 9.83164 40.8745 9.27971L40.8567 8.10463ZM38.7914 8.78119V8.69217C38.827 8.70998 38.8448 8.70998 38.8804 8.72778C38.8448 8.74558 38.827 8.76339 38.7914 8.78119Z"
                            fill="#1E1D1D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1903_17424">
                            <rect width="40.5757" height="18" fill="white" transform="translate(0.298828 0.324219)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="step == 3">
                <figure class="flex justify-center absolute top-[-2rem] right-[-35%]">
                  <img src="@/assets/images/utopicode-register.png" alt="img" />
                </figure>
                <!-- Title -->
                <div class="card--page--header mx-auto w-full lg:w-8/12 xl:w-8/12">
                  <Title>
                    <template #title>
                      <div class="flex justify-center">
                        <svg width="316" height="70" viewBox="0 0 316 70" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect x="35.125" y="0.792969" width="48.695" height="48.695" rx="5.37846"
                            transform="rotate(45 35.125 0.792969)" fill="#B9A3FF" />
                          <path
                            d="M37.673 41.1537H42.1898V44.1836H29.5654V41.1537H34.4468V27.6316L34.1382 27.5474L30.8559 30.437L28.8079 27.8841L34.6712 23.3954H37.673V41.1537Z"
                            fill="#1E1D1D" />
                          <rect x="67.1426" y="34.3203" width="68.1265" height="1.8121" fill="#B9A3FF" />
                          <rect x="167.287" y="0.792969" width="48.695" height="48.695" rx="5.37846"
                            transform="rotate(45 167.287 0.792969)" fill="#B9A3FF" />
                          <path
                            d="M165.653 40.789V41.1537H174.574V44.1836H161.136V41.294L169.497 33.1583C170.871 31.8117 171.376 30.7175 171.376 29.399C171.376 27.1827 169.917 25.9203 167.897 25.9203C165.765 25.9203 164.25 27.2949 164.11 29.3709H160.968C161.305 25.4714 164.166 22.9746 168.15 22.9746C171.881 22.9746 174.574 25.1628 174.574 29.0062C174.574 31.1664 173.705 32.9619 171.348 35.2343L165.653 40.789Z"
                            fill="#1E1D1D" />
                          <rect x="199.303" y="34.3203" width="68.1265" height="1.8121" fill="#B9A3FF" />
                          <rect x="290.223" y="11.1511" width="34.0457" height="34.0457" rx="4.57691"
                            transform="rotate(45 290.223 11.1511)" stroke="#B9A3FF" stroke-width="1.6031" />
                          <path
                            d="M292.366 35.6029C293.628 36.0742 294.403 37.1515 294.403 38.6159C294.403 40.7536 292.787 42.2012 290.38 42.2012C287.973 42.2012 286.205 40.72 285.986 38.3466H287.4C287.468 39.8784 288.612 40.8883 290.262 40.8883C291.861 40.8883 292.921 39.9288 292.921 38.5149C292.921 37.1178 291.861 36.192 290.279 36.192H289.336V34.9632H290.06C291.625 34.9632 292.652 34.1384 292.652 32.8423C292.652 31.5462 291.592 30.6541 290.127 30.6541C288.579 30.6541 287.535 31.6136 287.383 32.977H285.97C286.256 29.9958 287.922 28.5482 290.245 28.5482C292.534 28.5482 294.133 29.9453 294.133 31.9484C294.133 33.2108 293.477 34.1534 292.366 34.6079V35.6029Z"
                            fill="white" />
                        </svg>
                      </div>
                      <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative mt-5"
                        style="min-height: -webkit-fill-available">
                        <div class="flex-grow-0 flex-shrink-0 flex items-center">
                          <p class="left-0 mb-0 top-0 text-3xl text-center dark:text-white">
                            Falta muy poco
                          </p>
                        </div>
                      </div>
                    </template>
                  </Title>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div class="card--page--body">
                  <div class="form--group">
                    <Field name="country" as="select" class="form--element form--select w-full" :rules="validateDefault"
                      v-model="country">
                      <option value="" disabled>
                        {{ $t('registrarse-page.lbl-pais') }}
                      </option>
                      <option :value="pais" v-for="pais in paisesLista[$i18n.locale]" :key="pais">
                        {{ pais }}
                      </option>
                    </Field>
                    <ErrorMessage name="country" class="form--error" />
                  </div>
                  <div class="form--group">
                    <Field name="especialidad" as="select" class="form--element form--select w-full"
                      :rules="validateDefault" v-model="especialidad">
                      <option value="" disabled>
                        {{ $t('sign-up-evento-page.text-especialidad') }}
                      </option>
                      <option v-for="especi in especialidades.sort()" :value="especi" :key="especi">
                        {{ especi }}
                      </option>
                    </Field>
                    <ErrorMessage name="especialidad" class="form--error" />
                  </div>
                  <div class="form--group">
                    <Field name="yearsExperience" type="number" class="form--element w-full" :rules="validateDefault"
                      v-model="yearsExperience" :placeholder="$t('registrarse-page.lbl-years')" />
                    <ErrorMessage name="yearsExperience" class="form--error" />
                  </div>

                  <div class="flex justify-between">
                    <button class="btn btn-lg btn-secondary btn-rounded-lg text-black" role="button"
                      @click.prevent="prevStep">
                      <svg style="transform: rotate(180deg)" width="41" height="19" viewBox="0 0 41 19" fill="none"
                        xmlns="http://www.w3.org/2000/svg" class="mr-2">
                        <g clip-path="url(#clip0_1903_17424)">
                          <path
                            d="M40.8567 8.10463C39.0941 7.58831 37.4561 6.78713 35.9962 5.70107C34.1267 4.31235 32.56 2.44291 31.5095 0.324219L30.3878 0.876148C31.5451 3.17288 33.2187 5.16695 35.2484 6.6803C35.9427 7.19662 36.6727 7.65953 37.4561 8.06903H0.298828V9.31532H37.7588C36.9576 9.77822 36.2098 10.2945 35.5154 10.8999C32.8092 13.2322 31.5629 16.0275 30.9754 17.9503L32.1683 18.3064C32.7024 16.526 33.8419 13.9622 36.3166 11.8435C37.6519 10.704 39.1831 9.83164 40.8745 9.27971L40.8567 8.10463ZM38.7914 8.78119V8.69217C38.827 8.70998 38.8448 8.70998 38.8804 8.72778C38.8448 8.74558 38.827 8.76339 38.7914 8.78119Z"
                            fill="#1E1D1D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1903_17424">
                            <rect width="40.5757" height="18" fill="white" transform="translate(0.298828 0.324219)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ $t('registrarse-page.btn-regresar') }}
                    </button>
                    <button class="btn btn-lg btn-primary btn-rounded-lg" role="button" @click.prevent="nextStep">
                      {{ $t('registrarse-page.btn-register-next') }}
                      <svg width="41" height="19" viewBox="0 0 41 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="ml-2">
                        <g clip-path="url(#clip0_1903_17424)">
                          <path
                            d="M40.8567 8.10463C39.0941 7.58831 37.4561 6.78713 35.9962 5.70107C34.1267 4.31235 32.56 2.44291 31.5095 0.324219L30.3878 0.876148C31.5451 3.17288 33.2187 5.16695 35.2484 6.6803C35.9427 7.19662 36.6727 7.65953 37.4561 8.06903H0.298828V9.31532H37.7588C36.9576 9.77822 36.2098 10.2945 35.5154 10.8999C32.8092 13.2322 31.5629 16.0275 30.9754 17.9503L32.1683 18.3064C32.7024 16.526 33.8419 13.9622 36.3166 11.8435C37.6519 10.704 39.1831 9.83164 40.8745 9.27971L40.8567 8.10463ZM38.7914 8.78119V8.69217C38.827 8.70998 38.8448 8.70998 38.8804 8.72778C38.8448 8.74558 38.827 8.76339 38.7914 8.78119Z"
                            fill="#1E1D1D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1903_17424">
                            <rect width="40.5757" height="18" fill="white" transform="translate(0.298828 0.324219)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="step == 4">
                <figure class="flex justify-center absolute top-[-2rem] right-[-35%]">
                  <img src="@/assets/images/utopicode-register.png" alt="img" />
                </figure>
                <!-- Title -->
                <div class="card--page--header mx-auto w-full lg:w-8/12 xl:w-8/12">
                  <Title>
                    <template #title>
                      <div class="flex justify-center">
                        <svg width="334" height="70" viewBox="0 0 334 70" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect x="34.8379" y="0.591797" width="48.695" height="48.695" rx="5.37846"
                            transform="rotate(45 34.8379 0.591797)" fill="#B9A3FF" />
                          <path
                            d="M37.384 40.9487H41.9007V43.9785H29.2763V40.9487H34.1577V27.4265L33.8491 27.3423L30.5668 30.2319L28.5188 27.679L34.3822 23.1903H37.384V40.9487Z"
                            fill="#1E1D1D" />
                          <rect x="66.8555" y="34.1191" width="68.1265" height="1.8121" fill="#B9A3FF" />
                          <rect x="167" y="0.591797" width="48.695" height="48.695" rx="5.37846"
                            transform="rotate(45 167 0.591797)" fill="#B9A3FF" />
                          <path
                            d="M164.368 41.5839V41.9487H173.289V44.9785H159.851V42.0889L168.211 33.9532C169.586 32.6066 170.091 31.5125 170.091 30.1939C170.091 27.9776 168.632 26.7152 166.612 26.7152C164.48 26.7152 162.965 28.0898 162.825 30.1659H159.683C160.02 26.2663 162.881 23.7695 166.865 23.7695C170.596 23.7695 173.289 25.9577 173.289 29.8012C173.289 31.9613 172.42 33.7568 170.063 36.0292L164.368 41.5839Z"
                            fill="#1E1D1D" />
                          <rect x="199.016" y="34.1191" width="68.1265" height="1.8121" fill="#B9A3FF" />
                          <rect x="299.162" y="0.591797" width="48.695" height="48.695" rx="5.37846"
                            transform="rotate(45 299.162 0.591797)" fill="#B9A3FF" />
                          <path
                            d="M302.84 34.374C304.86 35.1034 306.123 36.8989 306.123 39.3116C306.123 42.9306 303.373 45.3713 299.277 45.3713C295.153 45.3713 292.18 42.7903 291.815 38.7785H294.845C294.929 41.0229 296.612 42.5097 299.053 42.5097C301.382 42.5097 302.924 41.107 302.924 39.031C302.924 36.983 301.353 35.6364 299.053 35.6364H297.482V32.9993H298.801C301.045 32.9993 302.532 31.765 302.532 29.8573C302.532 27.9496 300.989 26.631 298.857 26.631C296.556 26.631 295.013 28.0618 294.817 30.1378H291.787C292.236 26.2944 295.097 23.7695 299.109 23.7695C303.037 23.7695 305.73 26.1541 305.73 29.6048C305.73 31.7088 304.636 33.2799 302.84 34.0374V34.374Z"
                            fill="#1E1D1D" />
                        </svg>
                      </div>
                      <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative"
                        style="min-height: -webkit-fill-available">
                        <div class="flex-grow-0 flex-shrink-0 flex items-center">
                          <p class="left-0 mb-0 top-0 text-3xl text-center dark:text-white">
                            El último paso
                          </p>
                        </div>
                      </div>
                      <div class="flex justify-center items-center flex-grow-0 flex-shrink-0 relative"
                        style="min-height: -webkit-fill-available">
                        <div class="flex-grow-0 flex-shrink-0 flex items-center">
                          <p class="left-0 mb-0 top-0 text-xl text-center dark:text-white">
                            Crea una contraseña
                          </p>
                        </div>
                      </div>
                    </template>
                  </Title>
                </div>
                <!-- End Title -->
                <!-- Body -->
                <div class="card--page--body">
                  <div class="form--group">
                    <div class="form--icon form--icon--right">
                      <button type="button" @click="showPassword = !showPassword"
                        class="icon--wrapper text-white rounded-r-lg">
                        <img src="@/assets/images/icn-eye.svg" v-if="showPassword" />
                        <img src="@/assets/images/icn-eye-close.svg" v-else />
                      </button>
                      <Field name="password" :type="[showPassword ? 'text' : 'password']" class="form--element w-full"
                        :rules="validatePasswordComplex" v-model="password"
                        :placeholder="$t('registrarse-page.lbl-contraseña')" />
                    </div>
                    <ErrorMessage name="password" class="form--error" />
                  </div>
                  <div class="form--group">
                    <div class="form--icon form--icon--right">
                      <button type="button" @click="showRepeatPassword = !showRepeatPassword"
                        class="icon--wrapper text-white rounded-r-lg">
                        <img src="@/assets/images/icn-eye.svg" v-if="showRepeatPassword" />
                        <img src="@/assets/images/icn-eye-close.svg" v-else />
                      </button>
                      <Field name="repeatPassword" :type="[showRepeatPassword ? 'text' : 'password']"
                        class="form--element w-full" :rules="validateRepeatPassword" v-model="repeatPassword"
                        :placeholder="$t('registrarse-page.lbl-confirmar-contraseña')
                          " />
                    </div>
                    <ErrorMessage name="repeatPassword" class="form--error" />
                  </div>
                  <label class="form--label" for="contraseña">
                    <small class="text-xs leading-none">
                      <!-- {{ $t('form-validation.error-password-valid') }} -->
                    </small>
                  </label>
                  <div class="form--group">
                    <Field v-slot="{ field }" name="accepted" as="label" :rules="validateDefault"
                      class="ml-3 form--checkbox">
                      <input type="checkbox" name="accepted" v-bind="field" v-model="accepted" />
                      <span>
                        {{ $t('registrarse-page.lbl-politicas-1') }}
                        <a :href="`/es/politica-de-privacidad`" target="_blank" class="text-primary">
                          {{ $t('footer.terminos-condiciones') }}
                        </a>
                        {{ $t('registrarse-page.lbl-politicas-2') }}
                        <a :href="`/es/terminos-y-condiciones`" target="_blank" class="text-primary">
                          {{ $t('footer.politica-privacidad') }}
                        </a>
                        .
                      </span>
                    </Field>
                    <ErrorMessage name="accepted" class="form--error ml-3" />
                  </div>

                  <div class="flex justify-between">
                    <button class="btn btn-lg btn-secondary btn-rounded-lg text-muted-900" role="button"
                      @click.prevent="prevStep">
                      <svg style="transform: rotate(180deg)" width="41" height="19" viewBox="0 0 41 19" fill="none"
                        xmlns="http://www.w3.org/2000/svg" class="mr-2">
                        <g clip-path="url(#clip0_1903_17424)">
                          <path
                            d="M40.8567 8.10463C39.0941 7.58831 37.4561 6.78713 35.9962 5.70107C34.1267 4.31235 32.56 2.44291 31.5095 0.324219L30.3878 0.876148C31.5451 3.17288 33.2187 5.16695 35.2484 6.6803C35.9427 7.19662 36.6727 7.65953 37.4561 8.06903H0.298828V9.31532H37.7588C36.9576 9.77822 36.2098 10.2945 35.5154 10.8999C32.8092 13.2322 31.5629 16.0275 30.9754 17.9503L32.1683 18.3064C32.7024 16.526 33.8419 13.9622 36.3166 11.8435C37.6519 10.704 39.1831 9.83164 40.8745 9.27971L40.8567 8.10463ZM38.7914 8.78119V8.69217C38.827 8.70998 38.8448 8.70998 38.8804 8.72778C38.8448 8.74558 38.827 8.76339 38.7914 8.78119Z"
                            fill="#1E1D1D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1903_17424">
                            <rect width="40.5757" height="18" fill="white" transform="translate(0.298828 0.324219)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {{ $t('registrarse-page.btn-regresar') }}
                    </button>
                    <button class="btn btn-lg btn-primary btn-rounded-lg" role="button" type="submit"
                      @click.prevent="signUp">
                      {{ $t('registrarse-page.btn-register-final') }}
                      <svg width="41" height="19" viewBox="0 0 41 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="ml-2" v-if="!isLoading">
                        <g clip-path="url(#clip0_1903_17424)">
                          <path
                            d="M40.8567 8.10463C39.0941 7.58831 37.4561 6.78713 35.9962 5.70107C34.1267 4.31235 32.56 2.44291 31.5095 0.324219L30.3878 0.876148C31.5451 3.17288 33.2187 5.16695 35.2484 6.6803C35.9427 7.19662 36.6727 7.65953 37.4561 8.06903H0.298828V9.31532H37.7588C36.9576 9.77822 36.2098 10.2945 35.5154 10.8999C32.8092 13.2322 31.5629 16.0275 30.9754 17.9503L32.1683 18.3064C32.7024 16.526 33.8419 13.9622 36.3166 11.8435C37.6519 10.704 39.1831 9.83164 40.8745 9.27971L40.8567 8.10463ZM38.7914 8.78119V8.69217C38.827 8.70998 38.8448 8.70998 38.8804 8.72778C38.8448 8.74558 38.827 8.76339 38.7914 8.78119Z"
                            fill="#1E1D1D" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1903_17424">
                            <rect width="40.5757" height="18" fill="white" transform="translate(0.298828 0.324219)" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span v-else class="flex items-center">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                          fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                          </circle>
                          <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                          </path>
                        </svg>
                        {{ $t('general.cargando') }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="form--group" v-if="error">
                <p class="text-lg text-center text-fourth font-bold">
                  {{ $t('registrarse-page.error-tienes-cuenta-01') }}
                  <router-link :to="{ name: 'SignIn' }" class="underline">{{ $t('registrarse-page.btn-loguearse')
                    }}</router-link>
                  {{ $t('registrarse-page.error-tienes-cuenta-02') }}
                </p>
              </div>
            </Form>
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import paisesLista from '@/constants/countryList.json'
import phoneCodes from '@/constants/phoneCodes.json'
import especialidadLista from '@/constants/specialities.json'
import { mapActions, mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ROUTES from '@/constants/routes'
// import CodyAnimations from '@/components/animation/CodyHangingToogle'
import apolloClient from '@/graphql/apolloClient'
import signUpMutation from '@/graphql/mutations/signUp'
import firebase, { firebaseInstance, analytics } from '@/services/firebase'
import router from '@/router/index'
import { toastNotification } from '@/services/toastNotification'
import userProfileQuery from '@/graphql/queries/userProfile'
import {
  validateDefault,
  validateEmail,
  validatePasswordComplex,
  validatePhone,
  validateDefaultPhone
} from '@/services/validationRules'
import Loading from '@/components/Loading'

export default {
  name: 'SignUp',
  components: {
    CardPage,
    Title,
    Field,
    Form,
    ErrorMessage,
    Loading
  },
  data() {
    return {
      step: 1,
      paisesLista,
      fullname: '',
      firstname: '',
      lastname: '',
      surname: '',
      email: '',
      country: '',
      especialidad: '',
      phoneNumber: '',
      password: '',
      repeatPassword: '',
      yearsExperience: '',
      ROUTES,
      error: null,
      loading: false,
      isLoading: false,
      showPassword: false,
      showRepeatPassword: false,
      phoneCodeNumber: '+52',
      modalBienvenidaShow: false,
      phoneCodes,
      accepted: false,
      especialidades: especialidadLista,
      isSocialLogin: false,
      socialProvider: null,
      socialUserData: null
    }
  },
  computed: {
    ...mapGetters(['user', 'loading', 'token']),
    completeFields() {
      if (
        this.fullname !== '' &&
        this.email !== '' &&
        this.country !== '' &&
        this.especialidad !== '' &&
        this.phoneNumber !== '' &&
        this.password !== '' &&
        this.repeatPassword !== ''
      ) {
        return true
      } else {
        return false
      }
    },
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phoneNumber
    }
  },
  methods: {
    ...mapActions([
      'setLoadingOn',
      'setLoadingOff',
      'setLoggedIn',
      'fetchUser',
      'fetchProfile',
      'socialLogin'
    ]),
    nextStep() {
      const form = document.querySelector('#multiStepForm')

      // Valida el formulario antes de avanzar
      if (form.checkValidity()) {
        // Si es login social y estamos en el paso 3, ir directamente a finalizar

        if (this.isSocialLogin && this.step === 3) {
          this.signUp()
          // alert(`REGISTRO! ${this.isSocialLogin} ${this.step}`)
        } else {
          this.step += 1
        }
      } else {
        // Muestra los errores de validación
        form.reportValidity()
      }
    },
    // Método para retroceder al paso anterior
    prevStep() {
      if (this.step > 1) {
        this.step -= 1
      }
    },
    async signUp(values) {
      this.setLoadingOn()
      this.isLoading = true

      // Si no es login social, validar contraseñas
      if (!this.isSocialLogin && this.password !== this.repeatPassword) {
        this.setLoadingOff()
        toastNotification(
          this.$t('form-validation.error-password-repeat'),
          'error'
        )
        return
      }

      try {
        localStorage.clear()
        this.isLoading = false
        // Crear usuario en la base de datos
        const signUpResult = await apolloClient.mutate({
          mutation: signUpMutation,
          variables: {
            firstname: this.firstname,
            lastname: this.lastname,
            surname: this.surname,
            email: this.email,
            country: this.country,
            speciality: this.especialidad,
            fullname: `${this.firstname} ${this.lastname} ${this.surname}`,
            phonenumber: this.phoneNumberComplete,
            yearsOfExp: this.yearsExperience
          }
        })

        if (!signUpResult?.data) {
          this.setLoadingOff()
          this.isLoading = false
          throw new Error('Error al crear el usuario')
        }

        if (!this.isSocialLogin) {
        // Solo crear usuario en Firebase si no es login social

          const createdUser = await firebase.createUserWithEmailAndPassword(
            this.email,
            this.password
          )
          await createdUser.user.updateProfile({
            displayName: this.fullname
          })
          this.isLoading = false
        }

        analytics.logEvent('Registro')

        // Obtener el perfil después del registro
        this.setLoadingOff()
        this.isLoading = false
        await this.fetchUser(this.$router)


        // Mostrar mensaje de éxito
        toastNotification(
          this.$t('general.logueado-correctamente'),
          'success'
        )

        // Redirigir después de un registro exitoso
        setTimeout(() => {
          this.setLoadingOff()
          this.isLoading = false
          if (this.$route.query.redirectTo) {
            window.location = this.$route.query.redirectTo
          } else {
            window.location = '/es/perfil/mi-perfil'
          }
        }, 1500)

      } catch (e) {
        console.error('Error en registro:', e)
        this.isLoading = false
        this.modalBienvenidaShow = false
        this.setLoadingOff()
        this.error = true
        toastNotification(e?.message || this.$t('general.error-registro'), 'error')
      }
    },
    // RULES
    validateDefault,
    validateEmail,
    validatePasswordComplex,
    validatePhone,
    validateDefaultPhone,
    validateRepeatPassword(value) {
      if (!value) {
        return this.$t('form-validation.error-default')
      }
      if (value !== this.password) {
        return this.$t('form-validation.error-password-repeat')
      }
      return true
    },
    async getCurrentRoute() {
      if (router) {
        const currentRoute = router.currentRoute.value.name
        if (currentRoute === 'SignUp') {
          if (router.currentRoute.value.query.redirectTo) {
            await this.closeModalAndWait()
            console.log(
              'Redirecting to: ',
              router.currentRoute.value.query.redirectTo
            )
            router.push({ path: router.currentRoute.value.query.redirectTo })
          } else {
            await this.closeModalAndWait()
            console.log('Redirecting to: Profile')
            router.push({ name: 'Profile' })
          }
        }
      }
    },
    closeModalAndWait() {
      return new Promise((resolve) => {
        this.modalBienvenidaShow = false
        this.$nextTick(() => {
          console.log('Modal closed')
          resolve()
        })
      })
    }
  },
  created() {
    // Detectar parámetros de la URL
    this.isSocialLogin = false
    const query = this.$route.query
    if (query.email) {
      this.email = query.email
      this.isSocialLogin = true
    }
    if (query.fullname) {
      const names = query.fullname.split(' ')
      this.firstname = names[0]
      this.lastname = names.slice(1).join(' ')
    }
    if (query.photo) this.photo = query.photo
    if (query.fromSocial) {
      this.fromSocial = query.fromSocial
      this.isSocialLogin = true
    }
    if (query.provider) this.provider = query.provider
    if (query.step) this.step = parseInt(query.step)
  }
}
</script>
