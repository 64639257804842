<template>
  <!-- END SIDEBAR -->
  <CardPage class="main--wrapper" style="padding-bottom: 0 !important; background-color: #e3e9ff">
    <template #default>
      <Loading v-if="loading" class="absolute w-full h-full z-20 left-0 top-0 right-0 bottom-0 loading--dark"></Loading>
      <!-- Body -->
      <div class="content--wrapper flex flex-wrap lg:flex-nowrap" style="background-color: #e3e9ff !important">
        <!-- Aside Basic Information -->
        <aside class="w-full lg:w-5/12 bg-[#FFFFFF] pt-5 pb-7 lg:min-h-[980px]">
          <router-link :to="{ name: 'Profile' }" class="block ml-2 p-4 bg-transparent border-none">
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.74833 20.334L21.1943 35.78L19.2917 37.6673L0.625 19.0007L19.2917 0.333984L21.1943 2.22132L5.74833 17.6673H37.9583V20.334H5.74833Z"
                fill="#E2E8FE" />
            </svg>
          </router-link>
          <figure class="pt-10 pb-10 flex justify-center public-profile-avatar-section">
            <img :src="require(`@/assets/images/avatars/avatar-${userProfile?.avatar ? userProfile?.avatar : 16
              }.svg`)
              " class="rounded-full w-[180px]" alt="Cody Avatar" />
          </figure>
          <section class="public-profile-basicInformation px-14 pt-16 pb-14 text-black">
            <div class="text-4xl">
              {{
              userProfile.fullname
              ? userProfile.fullname?.split(' ')[0]
              : null
              }}
            </div>
            <div class="text-xl mt-4">
              {{ userProfile.speciality ? userProfile.speciality : null }}
            </div>
            <div class="mt-4">
              <span class="text-dark text-base">
                {{ this.yearsOfExp }} {{ $t('profile.years_experience') }}
              </span>
            </div>
            <div class="flex items-center text-xl mt-8">
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17.5013 24.5288C20.0749 22.4947 22.0169 20.545 23.3273 18.6798C24.6378 16.8145 25.293 15.0055 25.293 13.2529C25.293 11.8027 25.0283 10.566 24.4989 9.54297C23.9693 8.5199 23.3095 7.69198 22.5195 7.0592C21.7295 6.42618 20.8828 5.96612 19.9794 5.67901C19.0758 5.39214 18.2498 5.2487 17.5013 5.2487C16.7528 5.2487 15.9268 5.39214 15.0232 5.67901C14.1198 5.96612 13.2731 6.42618 12.4831 7.0592C11.6931 7.69198 11.0333 8.5199 10.5037 9.54297C9.97432 10.566 9.70964 11.8027 9.70964 13.2529C9.70964 15.0055 10.3648 16.8145 11.6753 18.6798C12.9857 20.545 14.9277 22.4947 17.5013 24.5288ZM17.5013 26.3351C14.4082 24.0068 12.1003 21.7583 10.5773 19.5896C9.05443 17.4212 8.29297 15.3088 8.29297 13.2525C8.29297 11.6994 8.57134 10.3376 9.12809 9.1672C9.68461 7.99703 10.4018 7.0141 11.2797 6.21841C12.1575 5.42295 13.1451 4.82641 14.2423 4.4288C15.3397 4.03095 16.4261 3.83203 17.5013 3.83203C18.5766 3.83203 19.6629 4.03095 20.7603 4.4288C21.8576 4.82641 22.8451 5.42295 23.7229 6.21841C24.6008 7.0141 25.318 7.99703 25.8745 9.1672C26.4313 10.3376 26.7096 11.6994 26.7096 13.2525C26.7096 15.3088 25.9482 17.4212 24.4253 19.5896C22.9023 21.7583 20.5944 24.0068 17.5013 26.3351ZM17.5013 15.1654C18.0989 15.1654 18.6024 14.9605 19.0118 14.5509C19.4215 14.1415 19.6263 13.638 19.6263 13.0404C19.6263 12.4428 19.4215 11.9393 19.0118 11.5298C18.6024 11.1202 18.0989 10.9154 17.5013 10.9154C16.9037 10.9154 16.4002 11.1202 15.9908 11.5298C15.5811 11.9393 15.3763 12.4428 15.3763 13.0404C15.3763 13.638 15.5811 14.1415 15.9908 14.5509C16.4002 14.9605 16.9037 15.1654 17.5013 15.1654ZM8.29297 30.7487V29.332H26.7096V30.7487H8.29297Z"
                  fill="#1E1D1D" />
              </svg>
              <span class="ml-2">{{ userProfile.city ? userProfile.city + ',' : null }}
                {{ userProfile.country ? userProfile.country : null }}</span>
            </div>
            <div class="mt-9">
              <h2 class="font-bold text-xl">Un poco de mí</h2>
              <p class="mb-0 mt-4">
                {{ this.about }}
              </p>
            </div>
            <div class="mt-9" v-if="userProfile?.minSalary">
              <h2 class="font-bold text-xl">
                {{ $t('profile.desired_salary') }}
              </h2>
              <p class="mb-0 mt-4">USD {{ this.minSalary }} {{ $t('profile.month') }}</p>
            </div>
            <!-- <div class="mt-9" v-if="userProfile?.realSalary">
              <h2 class="font-bold text-xl">Sueldo mensual bruto actual</h2>
              <p class="mb-0 mt-4">USD {{ this.realSalary }} montlhy</p>
            </div> -->
          </section>
          <div class="flex flex-col justify-center items-center mt-5">
            <button class="btn btn-green-50 btn-rounded-md btn-md bg-green-50" style="
                padding-top: 0.8rem;
                padding-bottom: 0.8rem;
                height: auto;
                width: 70%;
              " @click="abrirModal" type="button">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.75383 23.2462C7.27746 23.2462 6.87973 23.0867 6.56065 22.7676C6.24157 22.4485 6.08203 22.0508 6.08203 21.5744V11.465C6.08203 10.9886 6.24157 10.5909 6.56065 10.2718C6.87973 9.95274 7.27746 9.7932 7.75383 9.7932H9.9428V10.828H7.75383C7.59446 10.828 7.44846 10.8944 7.31583 11.027C7.1832 11.1596 7.11688 11.3056 7.11688 11.465V21.5744C7.11688 21.7338 7.1832 21.8798 7.31583 22.0124C7.44846 22.1451 7.59446 22.2114 7.75383 22.2114H18.8981C19.0575 22.2114 19.2035 22.1451 19.3361 22.0124C19.4688 21.8798 19.5351 21.7338 19.5351 21.5744V11.465C19.5351 11.3056 19.4688 11.1596 19.3361 11.027C19.2035 10.8944 19.0575 10.828 18.8981 10.828H16.7092V9.7932H18.8981C19.3745 9.7932 19.7722 9.95274 20.0913 10.2718C20.4104 10.5909 20.5699 10.9886 20.5699 11.465V21.5744C20.5699 22.0508 20.4104 22.4485 20.0913 22.7676C19.7722 23.0867 19.3745 23.2462 18.8981 23.2462H7.75383ZM12.8086 16.5197V5.25383L10.6354 7.42701L9.90296 6.68865L13.326 3.26562L16.749 6.68865L16.0166 7.42701L13.8434 5.25383V16.5197H12.8086Z"
                  fill="#1E1D1D" />
              </svg>
              <span class="ml-2"> {{ $t('profile.share_profile') }} </span>
            </button>
            <Modal v-if="showModalShareProfile" @cerrarModal="cerrarModal" :userProfile="userProfile" />
            <!-- <div
              @click="goToProfile()"
              class="btn text-white btn-rounded-md btn-md mt-6 flex justify-between items-center"
              style="
                padding-top: 0.8rem;
                padding-bottom: 0.8rem;
                height: auto;
                width: 70%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #5956d9;
              "
              role="button"
              tabindex="0"
            >
              <span>Volver al perfil personal</span>
              <svg
                width="43"
                height="19"
                viewBox="0 0 43 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.8033 8.42656C40.9793 7.89225 39.2842 7.06314 37.7734 5.93924C35.8388 4.50212 34.2174 2.56753 33.1304 0.375L31.9696 0.946163C33.1672 3.32294 34.8992 5.3865 36.9996 6.95259C37.7181 7.48691 38.4735 7.96595 39.2842 8.38971H0.832031V9.67944H39.5974C38.7683 10.1585 37.9945 10.6928 37.2759 11.3192C34.4754 13.7329 33.1857 16.6255 32.5777 18.6154L33.8121 18.9839C34.3648 17.1414 35.544 14.4883 38.105 12.2957C39.4869 11.1166 41.0714 10.2137 42.8217 9.64259L42.8033 8.42656ZM40.6661 9.1267V9.03457C40.7029 9.053 40.7213 9.053 40.7582 9.07142C40.7213 9.08985 40.7029 9.10827 40.6661 9.1267Z"
                  fill="white"
                />
              </svg>
            </div> -->
            <!-- <div
              @click="
                downloadPDF(id, 'Curriculum - ' + userProfile.fullname, 'light')
              "
              class="btn bg-linkedin text-white btn-rounded-md btn-md mt-6 flex justify-between items-center"
              style="
                padding-top: 0.8rem;
                padding-bottom: 0.8rem;
                height: auto;
                width: 70%;
                display: flex;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
              "
            >
              <svg
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="flex-grow-0 flex-shrink-0 w-[22.43px] h-[22.43px] relative"
                preserveAspectRatio="none"
              >
                <path
                  d="M6.86744 20.1294C6.43717 20.1294 6.07793 19.9853 5.78972 19.6971C5.50152 19.4089 5.35742 19.0497 5.35742 18.6194V4.81471C5.35742 4.38443 5.50152 4.02519 5.78972 3.73699C6.07793 3.44879 6.43717 3.30469 6.86744 3.30469H14.4528L18.4433 7.29519V18.6194C18.4433 19.0497 18.2992 19.4089 18.011 19.6971C17.7228 19.9853 17.3636 20.1294 16.9333 20.1294H6.86744ZM6.86744 19.1947H16.9333C17.0773 19.1947 17.2091 19.1348 17.3289 19.015C17.4487 18.8952 17.5086 18.7634 17.5086 18.6194V7.76254H13.9855V4.2394H6.86744C6.7235 4.2394 6.59163 4.29929 6.47183 4.41909C6.35203 4.53889 6.29213 4.67076 6.29213 4.81471V18.6194C6.29213 18.7634 6.35203 18.8952 6.47183 19.015C6.59163 19.1348 6.7235 19.1947 6.86744 19.1947ZM11.9004 17.523C12.7524 17.523 13.4687 17.2108 14.0493 16.5864C14.6299 15.9622 14.9202 15.2139 14.9202 14.3415V10.0633H13.9855V14.3415C13.9855 14.9514 13.7854 15.4783 13.3852 15.9223C12.9849 16.3663 12.49 16.5883 11.9004 16.5883C11.3143 16.5883 10.8203 16.3663 10.4184 15.9223C10.0163 15.4783 9.81528 14.9514 9.81528 14.3415V9.23658C9.81528 8.98841 9.892 8.77359 10.0454 8.5921C10.1987 8.41061 10.3916 8.31986 10.624 8.31986C10.8602 8.31986 11.0541 8.41061 11.2057 8.5921C11.3572 8.77359 11.433 8.98841 11.433 9.23658V14.3415H12.3677V9.23658C12.3677 8.73807 12.2017 8.30483 11.8698 7.93687C11.5378 7.56906 11.1225 7.38515 10.624 7.38515C10.1255 7.38515 9.71036 7.56906 9.37853 7.93687C9.04656 8.30483 8.88057 8.73807 8.88057 9.23658V14.3415C8.88057 15.2139 9.17173 15.9622 9.75405 16.5864C10.3365 17.2108 11.052 17.523 11.9004 17.523Z"
                  fill="white"
                ></path>
              </svg>
              <span>Descargar PDF</span>
            </div> -->
          </div>
        </aside>
        <!-- Aside Basic Information -->
        <div class="w-full lg:w-7/12 public-profile-info px-16 py-20">
          <div class="flex align-center">
            <button class="btn-white btn-md rounded-full w-[140px] uppercase mx-1" type="button" :class="section === 'Skills'
              ? 'bg-[#5956D9] text-white'
              : 'bg-white text-black'
              " style="font-size: 0.75rem" @click="section = 'Skills'">
              {{ $t('profile.skills') }}
            </button>
            <button class="btn-white btn-md rounded-full w-[140px] uppercase mx-1" type="button" :class="section === 'Education'
              ? 'bg-[#5956D9] text-white'
              : 'bg-white text-black'
              " style="font-size: 0.75rem" @click="section = 'Education'">
              {{ $t('profile.education') }}
            </button>
            <button class="btn-white btn-md rounded-full w-[140px] uppercase mx-1" type="button" :class="section === 'Experience'
              ? 'bg-[#5956D9] text-white'
              : 'bg-white text-black'
              " style="font-size: 0.75rem" @click="section = 'Experience'">
              {{ $t('profile.experience') }}
            </button>
            <button class="btn-white btn-md rounded-full w-[140px] uppercase mx-1" type="button" :class="section === 'Preferences'
              ? 'bg-[#5956D9] text-white'
              : 'bg-white text-black'
              " style="font-size: 0.75rem" @click="section = 'Preferences'">
              {{ $t('profile.preferences') }}
            </button>
          </div>
          <!-- Education Section -->
          <section v-if="this.section === 'Education'" class="text-dark mt-7">
            <div v-if="formalEducation?.length > 0" class="mt-6" style="border-top: 1px solid rgb(89, 86, 217)">
              <h3 class="text-xl mt-4 font-semibold">
                {{ $t('profile.education') }}
              </h3>
              <div class="flex justify-center mt-3">
                <div class="public-profile-formalEducation">
                  <div v-for="education in formalEducation" :key="education.name"
                    class="public-profile-formalEducation-education">
                    <div class="flex items-start mb-6 relative">
                      <div
                        class="flex items-start mr-8 mt-7 w-[152px] justify-between public-profile-formalExperience-badge"
                        style="margin-left: 50px; white-space: nowrap">
                        <span class="text-black text-right font-bold mt-0 w-32 block pr-3">
                          {{ formatedDate(education.startDate) }} -
                          {{
                          education.endDate && !isNaN(new Date(education.endDate))
                          ? formatedDate(education.endDate)
                          : 'Actual'
                          }}</span>
                        <div class="border-4 rounded-full h-6 w-6" style="border-color: #5956d9"></div>
                      </div>
                      <div class="w-96 mt-5">
                        <h3 class="text-dark font-semibold">
                          {{ education.grade }}
                        </h3>
                        <span class="text-primary">{{ education.school }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="certsAndCourses?.length > 0" class="mt-6 mb-6" style="
                border-top: 1px solid rgb(89, 86, 217);
                border-bottom: 1px solid rgb(89, 86, 217);
              ">
              <h3 class="text-xl mt-4 font-semibold">
                {{ $t('profile.certifications') }}
              </h3>
              <div class="flex justify-center mt-3">
                <div class="public-profile-formalEducation">
                  <div v-for="certs in certsAndCourses" :key="certs.name"
                    class="public-profile-formalEducation-education">
                    <!-- Item Certificación -->
                    <div class="flex items-center mb-6 relative">
                      <div
                        class="flex items-start mr-8 mt-3 w-[152px] justify-between public-profile-formalExperience-badge"
                        style="margin-left: 50px; white-space: nowrap">
                        <span class="text-black text-right font-bold mt-0 pr-3 w-32">{{ formatedDate(certs.startDate) }} -
                          {{
                          certs.endDate && !isNaN(new Date(certs.endDate))
                          ? formatedDate(certs.endDate)
                          : 'Actual'
                          }}</span>
                        <div class="border-4 rounded-full h-6 w-6" style="border-color: #5956d9"></div>
                      </div>
                      <div class="flex-grow w-96 mt-5">
                        <h4 class="text-dark font-semibold" style="font-size: 0.95rem; white-space: nowrap">
                          {{ certs.name }}
                        </h4>
                        <span class="text-primary" style="font-size: 0.95">
                          {{ certs.certifiedBy }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- End Education Section -->
          <!-- Expecience Section -->
          <section v-if="this.section === 'Experience'" class="text-dark mt-7">
            <div v-if="experience?.length > 0" class="pb-4" style="border-bottom: 1px solid #5956d9">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.professional_experience') }}
              </h3>
              <div class="flex justify-center mt-3">
                <div class="public-profile-formalEducation">
                  <div v-for="job in experience" :key="job.company" class="public-profile-formalEducation-education">
                    <!-- Item 1 -->
                    <div class="flex items-start mb-6 relative">
                      <!-- Fechas y círculo -->
                      <div
                        class="flex items-start mr-8 mt-7 w-[152px] justify-between public-profile-formalExperience-badge"
                        style="margin-left: 50px; white-space: nowrap">
                        <span class="text-black text-xl font-bold mt-0 pr-3 w-32 block text-right">
                          {{ formatedDate(job.startDate) }} -
                          {{
  job.endDate && !isNaN(new Date(job.endDate))
    ? formatedDate(job.endDate)
    : 'Actual'
}}
                        </span>
                        <div class="border-4 rounded-full h-6 w-6" style="border-color: #5956d9"></div>
                      </div>
                      <div class="pl-1 mt-6 w-96">
                        <h1 class="text-primary font-semibold text-2xl">
                          {{ job.position }}
                        </h1>
                        <div class="flex flex-wrap gap-1 my-2">
                          <span v-for="tech in job.technologies" :key="tech"
                            class="bg-primary text-black text-xs py-1 px-3 m-1 rounded-full whitespace-nowrap">
                            {{ tech }}
                          </span>
                        </div>
                        <h3 class="text-black font-semibold text-xl">
                          {{ job.company }}
                        </h3>
                        <span class="text-primary text-xl">{{
                          job.companyLocation
                        }}</span>
                        <h4 class="text-black mt-2">
                          {{ job.whatBuilt }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3 v-if="personalProjects?.length > 0" class="text-xl mt-4 font-semibold">
              {{ $t('profile.personal_projects') }}
            </h3>
            <div v-if="personalProjects?.length > 0" class="flex justify-center mt-3"
              style="border-bottom: 1px solid #5956d9">
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <div v-for="project in personalProjects" :key="project.name" class="w-full lg:w-full p-4">
                  <div class="flex items-start">
                    <!-- SVG -->
                    <svg class="mr-2" width="25" height="25" viewBox="0 0 20 22" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.6184 14.8587L10.9865 11.1536L18.6213 7.44774C18.8379 7.32717 19.0148 7.07592 19.0949 6.7662C19.1758 6.45214 19.1433 6.1352 19.0076 5.91789C18.8589 5.65076 18.5434 5.44067 18.1773 5.35836C17.8929 5.29411 17.6308 5.32082 17.4777 5.43056L10.3591 10.2504L11.0262 1.72614C11.0703 1.34061 10.9432 1.05687 10.6494 0.872772C10.2284 0.609975 9.55629 0.622248 9.11806 0.90165C8.91013 1.03449 8.67405 1.28285 8.72819 1.71459L9.39674 10.2497L2.28318 5.43344C1.77203 5.15043 1.03057 5.41684 0.756941 5.83486C0.46671 6.49329 0.720842 7.24053 1.13742 7.44846L8.76863 11.1536L1.14103 14.8566C0.915053 14.9844 0.740337 15.2334 0.660921 15.541C0.58006 15.8551 0.612548 16.172 0.748278 16.3886C0.897004 16.6557 1.2125 16.8658 1.57854 16.9481C1.863 17.0124 2.12507 16.9857 2.27813 16.8759L9.39674 12.0561L8.72964 20.5803C8.67477 21.0236 8.91085 21.272 9.11878 21.4048C9.55629 21.6842 10.2292 21.6965 10.6501 21.4337C10.9439 21.2496 11.071 20.9659 11.0277 20.5897L10.3591 12.0568L17.4727 16.873C17.9846 17.156 18.726 16.8896 18.9989 16.4716C19.2891 15.8139 19.035 15.0667 18.6184 14.8587Z"
                        fill="#DB7E6E"></path>
                    </svg>
                    <div>
                      <h2 class="dark:text-dark text-dark mb-2 lg:text-2xl">
                        {{ project.name }}
                      </h2>
                      <div class="flex flex-wrap">
                        <span v-for="(tech, index) in project.technologies" :key="tech"
                          class="text-primary text-xl font-semibold whitespace-nowrap">
                          {{ tech }}
                          <span v-if="
                            index < project.technologies?.length - 1 &&
                            project.technologies?.length > 2
                          ">
                            -
                          </span>
                        </span>
                      </div>
                      <p class="dark:text-dark text-dark">
                        {{ project.whatBuilt }}
                      </p>
                      <a :href="project.appOrRepoLink" style="
                          color: rgb(40, 185, 154);
                          margin-top: -15px !important;
                        " class="flex items-center">
                        <svg class="mr-2" width="19" height="9" viewBox="0 0 19 9" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75612 8.23994H4.21762C3.08962 8.23994 2.12812 7.84252 1.33312 7.04769C0.538125 6.25285 0.140625 5.2916 0.140625 4.16394C0.140625 3.03627 0.538125 2.07469 1.33312 1.27919C2.12812 0.483689 3.08962 0.0859375 4.21762 0.0859375H7.75612V1.08594H4.21762C3.37146 1.08594 2.64704 1.38727 2.04437 1.98994C1.44187 2.59244 1.14062 3.31677 1.14062 4.16294C1.14062 5.0091 1.44187 5.73344 2.04437 6.33594C2.64704 6.9386 3.37146 7.23994 4.21762 7.23994H7.75612V8.23994ZM5.64062 4.66294V3.66294H12.6406V4.66294H5.64062ZM10.5251 8.23994V7.23994H14.0636C14.9098 7.23994 15.6342 6.9386 16.2369 6.33594C16.8394 5.73344 17.1406 5.0091 17.1406 4.16294C17.1406 3.31677 16.8394 2.59244 16.2369 1.98994C15.6342 1.38727 14.9098 1.08594 14.0636 1.08594H10.5251V0.0859375H14.0636C15.1916 0.0859375 16.1531 0.483354 16.9481 1.27819C17.7431 2.07302 18.1406 3.03427 18.1406 4.16194C18.1406 5.2896 17.7431 6.25119 16.9481 7.04669C16.1531 7.84219 15.1916 8.23994 14.0636 8.23994H10.5251Z"
                            fill="#28B99A" />
                        </svg>
                        {{ project.appOrRepo }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 v-if="userProfile?.socialNetworks?.portfolio?.length > 0" class="text-xl mt-4 font-semibold">
              {{ $t('profile.portfolio') }}
            </h2>
            <div v-if="userProfile?.socialNetworks?.portfolio?.length > 0" class="flex justify-center mt-3">
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <div class="w-full lg:w-full p-4">
                  <div class="flex items-start">
                    <svg class="mr-2" width="25" height="25" viewBox="0 0 20 22" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.6184 14.8587L10.9865 11.1536L18.6213 7.44774C18.8379 7.32717 19.0148 7.07592 19.0949 6.7662C19.1758 6.45214 19.1433 6.1352 19.0076 5.91789C18.8589 5.65076 18.5434 5.44067 18.1773 5.35836C17.8929 5.29411 17.6308 5.32082 17.4777 5.43056L10.3591 10.2504L11.0262 1.72614C11.0703 1.34061 10.9432 1.05687 10.6494 0.872772C10.2284 0.609975 9.55629 0.622248 9.11806 0.90165C8.91013 1.03449 8.67405 1.28285 8.72819 1.71459L9.39674 10.2497L2.28318 5.43344C1.77203 5.15043 1.03057 5.41684 0.756941 5.83486C0.46671 6.49329 0.720842 7.24053 1.13742 7.44846L8.76863 11.1536L1.14103 14.8566C0.915053 14.9844 0.740337 15.2334 0.660921 15.541C0.58006 15.8551 0.612548 16.172 0.748278 16.3886C0.897004 16.6557 1.2125 16.8658 1.57854 16.9481C1.863 17.0124 2.12507 16.9857 2.27813 16.8759L9.39674 12.0561L8.72964 20.5803C8.67477 21.0236 8.91085 21.272 9.11878 21.4048C9.55629 21.6842 10.2292 21.6965 10.6501 21.4337C10.9439 21.2496 11.071 20.9659 11.0277 20.5897L10.3591 12.0568L17.4727 16.873C17.9846 17.156 18.726 16.8896 18.9989 16.4716C19.2891 15.8139 19.035 15.0667 18.6184 14.8587Z"
                        fill="#DB7E6E"></path>
                    </svg>
                    <div>
                      <a :href="userProfile?.socialNetworks?.portfolio" style="white" class="flex items-center">
                        <svg class="mr-2" width="19" height="9" viewBox="0 0 19 9" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.75612 8.23994H4.21762C3.08962 8.23994 2.12812 7.84252 1.33312 7.04769C0.538125 6.25285 0.140625 5.2916 0.140625 4.16394C0.140625 3.03627 0.538125 2.07469 1.33312 1.27919C2.12812 0.483689 3.08962 0.0859375 4.21762 0.0859375H7.75612V1.08594H4.21762C3.37146 1.08594 2.64704 1.38727 2.04437 1.98994C1.44187 2.59244 1.14062 3.31677 1.14062 4.16294C1.14062 5.0091 1.44187 5.73344 2.04437 6.33594C2.64704 6.9386 3.37146 7.23994 4.21762 7.23994H7.75612V8.23994ZM5.64062 4.66294V3.66294H12.6406V4.66294H5.64062ZM10.5251 8.23994V7.23994H14.0636C14.9098 7.23994 15.6342 6.9386 16.2369 6.33594C16.8394 5.73344 17.1406 5.0091 17.1406 4.16294C17.1406 3.31677 16.8394 2.59244 16.2369 1.98994C15.6342 1.38727 14.9098 1.08594 14.0636 1.08594H10.5251V0.0859375H14.0636C15.1916 0.0859375 16.1531 0.483354 16.9481 1.27819C17.7431 2.07302 18.1406 3.03427 18.1406 4.16194C18.1406 5.2896 17.7431 6.25119 16.9481 7.04669C16.1531 7.84219 15.1916 8.23994 14.0636 8.23994H10.5251Z"
                            fill="#ffffff" />
                        </svg>
                        {{ userProfile?.socialNetworks?.portfolio }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- End Expecience Section -->
          <!-- Skills Section -->
          <!-- Expecience Section -->
          <section v-if="this.section === 'Skills'" class="text-dark mt-7">
            <div v-if="technologies?.length > 0" class="pb-7" style="border-bottom: 1px solid #5956d9">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.technologies') }}
              </h3>
              <div class="w-full flex flex-wrap justify-between items-end">
                <div class="w-full lg:w-[46%] !text-md mt-2" v-for="technology in technologies"
                  :key="technology.techName">
                  <ProgressBarLight class="w-full" :title="technology.techName" :years="technology.expYears"
                    :level="technology.level" :porcentaje="calculateTechLevel(technology.level)" />
                </div>
              </div>
            </div>
            <div class="pb-7 mt-5" style="border-bottom: 1px solid #5956d9" v-if="techAssesments?.length > 0">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.technical_skills_test') }}
              </h3>
              <div class="w-full mt-6 flex items-start justify-between">
                <div v-for="certs in techAssesments" :key="certs.name" class="flex">
                  <div>
                    <img :src="require(`@/assets/images/tests/squaregorilla.png`)" alt="Assessment Icon"
                      class="max-w-none" style="width: 70px; height: 70px" />
                  </div>
                  <div class="ml-3">
                    <h3 class="text-xl mb-1 font-semibold">{{ certs.name }}</h3>
                    <div class="flex flex-wrap mt-2">
                      <span class="text-purple-300 text-xl font-semibold uppercase">{{ certs.certifiedBy }}</span>
                    </div>
                    <a :href="certs.file" target="_blank">
                      <div
                        class="rounded-full uppercase px-2 py-1 mr-2 mt-2 text-primary border flex items-center justify-center"
                        style="border: 2px solid #b9a3ff; font-size: 0.9rem">
                        {{ $t('profile.show_certification') }}
                      </div>
                    </a>
                    <h3 class="text-lg text-black mt-2">
                      <span>{{ $t('profile.issued') }}: {{ certs.startDate }}</span>
                    </h3>
                    <!-- <div>
                      <img
                        :src="
                          require(`@/assets/images/tests/certificado-uno.png`)
                        "
                        alt="Assessment Icon"
                        class="max-w-none"
                        style="width: 220px; height: 120px"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div v-if="takenAssesments?.length > 0" class="pb-7 mt-5" style="border-bottom: 1px solid #5956d9">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.skills') }}
              </h3>
              <div v-for="result in takenAssesments" :key="result.id" class="w-full mt-6 flex items-start">
                <div>
                  <img :src="require(`@/assets/images/badges/${result.assesment.photo}`)
                    " alt="Assessment Icon" class="max-w-none" style="width: 80px; height: auto" />
                </div>
                <div class="ml-3">
                  <h3 class="text-xl mb-1 font-semibold">
                    {{ result.assesment.name }}
                  </h3>
                  <div class="flex flex-wrap mt-2">
                    <div v-for="subAssesment in result.subAssesmentsResult" :key="subAssesment.id"
                      class="rounded-full uppercase px-2 py-1 mr-2 mt-2 text-black"
                      style="background: #b9a3ff; font-size: 0.75rem">
                      {{ subAssesment.assesment.name }}
                    </div>
                  </div>
                  <p class="text-sm mt-2">
                    {{ $t('profile.assessment_description', {
                      name: result.assesment.name, length:
                        result.assesment.testLength
                    }) }}
                  </p>
                  <h3 class="text-xl font-semibold">
                    Score:
                    <span style="color: #5956D9)">
                      {{ getTotalScore(result) }}/{{
                        getTotalTestLength(result)
                      }}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div v-if="this.englishLevel" class="pb-7 mt-6">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.languages') }}
              </h3>
              <div class="w-full public-profile-especiality-section mt-4">
                <div class="flex">
                  <span v-for="star in 5" :key="star" class="mr-1">
                    <svg v-if="star <= this.englishLevel" width="22" height="20" viewBox="0 0 22 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.3206 0.369775C10.4702 -0.09088 11.1219 -0.09088 11.2716 0.369775L13.3813 6.8628C13.4483 7.06882 13.6402 7.20829 13.8569 7.20829H20.684C21.1684 7.20829 21.3698 7.8281 20.9779 8.1128L15.4546 12.1257C15.2794 12.253 15.206 12.4787 15.273 12.6847L17.3827 19.1778C17.5324 19.6384 17.0051 20.0215 16.6133 19.7368L11.09 15.7239C10.9147 15.5965 10.6774 15.5965 10.5022 15.7239L4.97889 19.7368C4.58703 20.0215 4.05979 19.6384 4.20947 19.1778L6.31918 12.6847C6.38612 12.4787 6.31279 12.253 6.13755 12.1257L0.614247 8.1128C0.22239 7.8281 0.423778 7.20829 0.90814 7.20829H7.73531C7.95193 7.20829 8.1439 7.06881 8.21084 6.8628L10.3206 0.369775Z"
                        fill="#5956d9" />
                    </svg>
                    <svg v-else width="22" height="20" viewBox="0 0 22 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.5862 0.369775C10.7359 -0.09088 11.3876 -0.09088 11.5372 0.369775L13.6469 6.8628C13.7139 7.06882 13.9059 7.20829 14.1225 7.20829H20.9496C21.434 7.20829 21.6354 7.8281 21.2435 8.1128L15.7202 12.1257C15.545 12.253 15.4717 12.4787 15.5386 12.6847L17.6483 19.1778C17.798 19.6384 17.2708 20.0215 16.8789 19.7368L11.3556 15.7239C11.1804 15.5965 10.9431 15.5965 10.7678 15.7239L5.24452 19.7368C4.85266 20.0215 4.32542 19.6384 4.47509 19.1778L6.58481 12.6847C6.65174 12.4787 6.57841 12.253 6.40317 12.1257L0.879872 8.1128C0.488015 7.8281 0.689403 7.20829 1.17376 7.20829H8.00094C8.21755 7.20829 8.40953 7.06881 8.47647 6.8628L10.5862 0.369775Z"
                        fill="#333333" />
                    </svg>
                  </span>
                </div>
                <h4 class="text-lg mt-1">
                  {{ $t('profile.english_level') }}
                </h4>
                <p class="text-xs uppercase mt-4" style="color: #5956d9">
                  {{ englishLevelDescription(this.englishLevel) }}
                </p>
              </div>
            </div>
          </section>
          <!-- End Experience Section -->
          <!-- Preferences Section -->
          <section v-if="this.section === 'Preferences'" class="text-dark mt-7">
            <div v-if="this.personsInCharge" class="pb-7">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.people_in_charge') }}
              </h3>
              <div class="flex flex-wrap mt-2">
                <div class="rounded-full uppercase px-16 py-2 mr-2 mt-2 text-black font-semibold"
                  style="background: #b9a3ff; font-size: 0.75rem">
                  {{ this.personsInCharge }}
                </div>
              </div>
            </div>
            <div v-if="this.industries?.length > 0" class="pb-7">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.companies_looking') }}
              </h3>
              <div class="flex flex-wrap mt-2">
                <div v-for="industry in industries" :key="industry"
                  class="rounded-full uppercase px-4 py-2 mr-2 mt-2 text-black font-semibold"
                  style="background: #b9a3ff; font-size: 0.75rem">
                  {{ industry }}
                </div>
              </div>
            </div>
            <div v-if="this.jobModePreference?.length > 0" class="pb-7">
              <h3 class="text-xl font-semibold">
                {{ $t('profile.job_mode') }}
              </h3>
              <div class="flex flex-wrap mt-2">
                <div class="rounded-full uppercase px-16 py-2 mr-2 mt-2 text-black font-semibold"
                  style="background: #b9a3ff; font-size: 0.75rem">
                  {{ this.jobModePreference?.join(', ') }}
                </div>
              </div>
            </div>
          </section>
          <!-- End Experience Section -->
        </div>
      </div>
      <!-- End Body -->
    </template>
  </CardPage>
</template>

<!-- <style>
.public-profile-formalEducation-badge::after {
  min-height: 6.3rem !important;
}
</style> -->
<script>
import CardPage from '@/components/CardPage'
import ProgressBarLight from '@/components/ProgressBarLight'
// import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
// import BasicInformationSection from '@/components/profile/BasicInformationSection'
import Loading from '@/components/Loading'
import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import publicProfileQuery from '@/graphql/queries/publicProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import Modal from '@/components/ModalProfileShareEmpresarial'

export default {
  name: 'Profile',
  data() {
    return {
      userProfile: {},
      modalBienvenidaShow: false,
      assesmentCategories: [],
      showModalShareProfile: false,
      // section: 'Education',
      section: 'Skills',
      technologies: [],
      formalEducation: [],
      certsAndCourses: [],
      experience: [],
      personalProjects: [],
      takenAssesments: [],
      englishLevel: null,
      about: null,
      id: null,
      decryptedId: null
    }
  },
  components: {
    CardPage,
    ProgressBarLight,
    Loading,
    Modal
    // BasicInformationSection
  },
  updated() {
    this.getAssesmentCategories()
  },
  async beforeCreate() {},
  async mounted() {
    await this.setLoadingOn()
    await this.getUserProfile()
    await this.getAssesmentCategories()
    await this.setLoadingOff()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['loading', 'user', 'profile', 'token'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    abrirModal() {
      this.showModalShareProfile = true
    },
    cerrarModal() {
      this.showModalShareProfile = false
    },
    sectionStyle(button) {
      if (
        (this.section === 'Education' && button === 'Education') ||
        (this.section === 'Experience' && button === 'Experience') ||
        (this.section === 'Skills' && button === 'Skills')
      ) {
        return { backgroundColor: 'white', color: 'black' }
      } else {
        return { backgroundColor: '#4B4267', color: 'white' }
      }
    },

    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userId = this.$route.params.id
        console.log(userId)
        this.decryptedId = this.decryptId(userId)

        console.log('decripted id', this.decryptedId)

        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: publicProfileQuery,
          variables: {
            id: this.decryptedId
          }
        })

        console.log('THISSSS', userProfileData?.data)

        this.userProfile = userProfileData?.data?.userPublicProfile
        this.technologies = this.userProfile?.technologies?.slice()?.reverse()
        this.formalEducation = this.userProfile?.formalEducation
          ?.slice()
          ?.reverse()
        this.certsAndCourses = this.userProfile.certsAndCourses
          ?.slice()
          ?.reverse()
        this.techAssesments = this.userProfile?.techAssesments
          ?.slice()
          ?.reverse()
        this.experience = this.userProfile?.pastJobs?.slice()?.sort((a, b) => {
          const endDateA = a.endDate ? new Date(a.endDate) : new Date()
          const endDateB = b.endDate ? new Date(b.endDate) : new Date()
          return endDateB - endDateA
        })
        this.personalProjects = this.userProfile?.pastProjects
          ?.slice()
          ?.reverse()
        this.takenAssesments = this.userProfile?.takenAssesments
          ?.slice()
          ?.reverse()
        this.englishLevel = this.userProfile?.englishLevel
        this.about = this.userProfile?.about
        this.id = this.userProfile?.id
        this.yearsOfExp = this.userProfile?.yearsOfExp
        this.minSalary = this.userProfile?.minSalary
        this.realSalary = this.userProfile?.realSalary
        this.personsInCharge = this.userProfile?.personsInCharge
        this.industries = this.userProfile?.industries?.slice()?.reverse()
        this.jobModePreference = this.userProfile.jobModePreference
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    onModalClose() {
      this.modalBienvenidaShow = false
    },
    calculateTechLevel: (level) => {
      switch (level) {
        case '1':
          return 25
        case '2':
          return 50
        case '3':
          return 75
        case '4':
          return 100
        default:
          return 0
      }
    },
    formatedDate(date) {
      if (date) {
        const parsedDate = new Date(date)
        if (!isNaN(parsedDate)) {
          const year = parsedDate.getUTCFullYear()
          return `${year}`
        }
      }
      return ''
    },
    getTotalScore(result) {
      return result.subAssesmentsResult.reduce(
        (total, subAssesment) => total + subAssesment.totalScore,
        0
      )
    },
    getTotalTestLength(result) {
      return result.subAssesmentsResult.reduce(
        (total, subAssesment) => total + subAssesment.assesment.testLength,
        0
      )
    },
    englishLevelDescription(level) {
      const numericLevel = parseInt(level, 10)
      if (!numericLevel) return ''
      switch (numericLevel) {
        case 1:
        case 2:
          return 'Básico'
        case 3:
          return 'Intermedio'
        case 4:
          return 'Avanzado'
        case 5:
          return 'Profesional'
        default:
          return 'Desconocido'
      }
    },
    truncateText(text, length) {
      return text?.length > length ? text.substring(0, length) + '...' : text
    },
    encryptId(id) {
      return id.toString(16)
    },
    decryptId(id) {
      return parseInt(id, 16).toString()
    },
    goToProfile() {
      const userId = this.$route.params.id
      if (userId) {
        console.log()
        this.$router.push(`/es/ver-perfil/${userId}`)
      } else {
        console.error('El perfil o el ID del perfil no están disponibles')
      }
    },
    async downloadPDF(userId, pdfName, background, wait = false) {
      try {
        this.setLoadingOn()
        const userId = this.$route.params.id
        const domain = location.hostname
        const protocol = location.protocol
        const profileUrl = `${protocol}//${domain}/es/profile-light-pdf/${userId}`
        const generatorUrl = process.env.VUE_APP_CV_PDF_GENERATOR_URL
        const apiUrl = `${generatorUrl}?background=${background}&url=${profileUrl}`
        const response = await fetch(apiUrl)
        if (!response.ok) throw new Error('Error al obtener el PDF')

        // Obtener el contenido en formato JSON
        const data = await response.json()

        // Decodificar el contenido del PDF desde base64
        const pdfContent = atob(data.data) // Suponiendo que la propiedad es 'data'

        // Convertir el contenido decodificado a un array de bytes
        const byteArray = new Uint8Array(pdfContent?.length)
        for (let i = 0; i < pdfContent?.length; i++) {
          byteArray[i] = pdfContent.charCodeAt(i)
        }

        // Crear un Blob desde el array de bytes
        const blob = new Blob([byteArray], { type: 'application/pdf' })

        // Crear una URL temporal para el archivo PDF
        const url = window.URL.createObjectURL(blob)

        // Crear un enlace invisible y hacer clic en él para iniciar la descarga
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = pdfName.trim() // Usar el nombre proporcionado para la descarga
        document.body.appendChild(a)
        a.click()

        // Limpiar después de la descarga
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        // Esperar si se requiere
        if (wait) await new Promise((resolve) => setTimeout(resolve, 1000)) // Espera de 1 segundo por defecto
      } catch (error) {
        console.error('Error durante la descarga del PDF:', error)
      } finally {
        // Indicar que la descarga ha terminado
        this.setLoadingOff()
      }
    }
  }
}
</script>
